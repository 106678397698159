<template>
  <v-card class="mb-5">
    <v-card-text class="pt-1">
      <custom-snackbar
        ref="snackbar"
      />
      <v-toolbar flat class="d-flex flex-row-reverse">
        <v-col class="col-12 col-md-1 my-0 py-0 pr-0">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
               max-width="20px"
                dark
                class="btn-block v-btn--height-fix"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>
                  mdi-printer
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="exportFile('PDF')">
                <v-list-item-title>Exportar PDF</v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportFile('Excel')">
                <v-list-item-title>Exportar Excel</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-toolbar>
      <v-row class="mt-3">
        <v-col class="py-0 mb-2 col-12 col-md-3">
          <v-autocomplete
            outlined
            dense
            label="Empresa"
            item-text="nome"
            item-value="id"
            v-model="empresaId"
            :items="empresasOptions"
            :rules="empresaRule"
            @change="fieldsSelected"
          />
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-3">
          <v-autocomplete
            outlined
            dense
            label="Produção"
            item-text="nome"
            item-value="id"
            v-model="producaoId"
            :rules="producaoRule"
            :items="producoesOptions"
            :disabled="producoesDisabled"
            @change="fieldsSelected"
          />
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-3">
          <v-autocomplete
            outlined
            dense
            label="Talhão"
            item-text="nome"
            item-value="id"
            v-model="talhaoId"
            :items="talhoesOptions"
            :rules="talhaoRule"
            :disabled="talhoesDisabled"
            @change="fieldsSelected"
          />
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-2">
          <v-text-field
            outlined
            dense
            label="Ano"
            v-model.number="ano"
            :rules="anoRule"
            @keydown="$stringFormat.validarNumero"
            :maxlength = "4"
            :disabled="fetchButtonDisabled"
            @change="fieldsSelected"
          />
        </v-col>
      <v-col class="col-12 col-xs-12 col-sm-12 col-lg-1 py-0">
          <v-tooltip
            top
            v-model="pendingSearch"
            color="warning"
            max-width="139px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              max-width="20px"
              outlined
              block
              color="info"
              class="v-btn--height-fix"
              item-text="nome"
              item-value="id"
              @click="loadData"
              :disabled="fetchButtonDisabled"
            >
              <v-icon dark>
                mdi-cached
               </v-icon>
              </v-btn>
              </template>
              <span>Busca pendente</span>
            </v-tooltip>
          </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            dense
            disable-pagination
            sort-by="updated_at"
            sort-desc="true"
            :headers="headers"
            :items="data"
            :items-per-page="-1"
            :hide-default-footer="true"
            @current-items="getDataFile"
          >
            <template v-slot:progress>
              <v-progress-linear
                absolute
                indeterminate
                color="green"
              ></v-progress-linear>
            </template>

            <template v-slot:[`item.oferta_antiga`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.oferta_antiga, 0, 4) }}</span>
            </template>

            <template v-slot:[`item.oferta_atual`]="{ item }">
              <span class="num_margin">{{ $stringFormat.formatNumber(item.oferta_atual, 0, 4) }}</span>
            </template>

            <template v-slot:[`item.updated_at`]="{ item }">
              <span class="num_margin">{{ formatDateHour(item.updated_at) }}</span>
            </template>

            <template v-slot:[`item.mes_nr`]="{ item }">
              <span class="num_margin">{{ getMonthDate(item.mes_nr) }}</span>
            </template>

            <template v-slot:no-data>
              <v-spacer />
              <div v-if="dataError">
                <v-row class="justify-center mt-1">
                  <v-col class="col-lg-6">
                    <custom-alert
                      :color="$label.getColor(0)"
                      text="Não foi possível obter essas informações"
                    />
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <span v-if="showData == true && loading == true">
                  Carregando...
                  <v-progress-circular
                    :size="20"
                    :width="2"
                    color="primary"
                    indeterminate
                  />
                  <v-spacer />
                </span>
                <span v-else-if="showData == true && loading == false">
                  Nenhum registro encontrado para a Empresa, Produção, Talhão e Ano informados.
                </span>
                <div class="text-center" v-else>
                  <h6 class="my-5">Selecione uma Empresa, uma Produção, um Talhão e informe um Ano.</h6>
                </div>
                <v-spacer />
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

    </v-card-text>
  </v-card>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: function () {
    return {
      search: '',
      loading: false,
      data: [],
      ano: new Date().getFullYear(),
      showData: false,

      empresaRule: [(v) => !!v || 'Selecione a Empresa'],
      producaoRule: [(v) => !!v || 'Selecione a Produção'],
      talhaoRule: [(v) => !!v || 'Selecione o Talhão'],
      anoRule: [(v) => !!v || 'Informe o Ano'],
      empresasOptions: this.$empresas.list(false),
      producoesOptions: [{ id: '', sigla: 'Selecione uma Empresa' }],
      talhoesOptions: [{ id: '', nome: 'Selecione uma Produção' }],
      producoesDisabled: true,
      talhoesDisabled: true,
      fetchButtonDisabled: true,

      empresaId: 0,
      producaoId: 0,
      talhaoId: 0,

      nameFile: '',
      dataFile: [],
      tableHeader: {
        'Data e Hora': 'updated_at',
        'Mês Alterado': 'mes_nr',
        'Valor Anterior': 'oferta_antiga',
        'Valor Atual': 'oferta_atual',
        Usuário: 'username',
      },
      headerFile: [],
    }
  },

  computed: {
    headers () {
      return [
        { text: 'Data e Hora', value: 'updated_at', class: 'custom-table--header' },
        { text: 'Mês Alterado', value: 'mes_nr', class: 'custom-table--header' },
        { text: 'Valor Anterior', value: 'oferta_antiga', align: 'right', class: 'custom-table--header' },
        { text: 'Valor Atual', value: 'oferta_atual', align: 'right', class: 'custom-table--header' },
        { text: 'Usuário', value: 'username', class: 'custom-table--header' },
      ]
    },
  },

  watch: {
    empresaId (value) {
      this.producaoId = null
      this.talhaoId = null
      this.producoesDisabled = false
      this.talhoesDisabled = true
      this.fetchButtonDisabled = true

      this.producoesOptions = this.$producoes.list(value, false)
    },

    producaoId (value) {
      this.talhaoId = null

      if (this.producaoId) {
        this.fetchTalhoesOptions()
      }
    },

    talhaoId (value) {
      if (!this.talhaoId) {
        this.fetchButtonDisabled = true
        return
      }

      this.fetchButtonDisabled = false
    },
  },

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Alterações Oferta de Grama' },
    ])
  },

  methods: {
    fetchTalhoesOptions () {
      this.producaoOptionsFilter = []
      const params = '?empresa_id=' + this.empresaId + '&producao_id=' + this.producaoId

      ApiService.get('/talhao', params).then((res) => {
        const talhoesFiltro = []

        for (const talhao of res.data.data) {
          talhoesFiltro.push({
            id: talhao.id,
            nome: talhao.nome,
          })
        }

        this.talhoesOptions = talhoesFiltro
        this.talhoesDisabled = false
      })
    },
    fieldsSelected () {
      this.pendingSearch = true
    },
    loadData () {
      this.showData = true
      this.loading = true

      const params = {
        empresa_id: this.empresaId,
        producao_id: this.producaoId,
        talhao_id: this.talhaoId,
        ano: this.ano,
      }

      ApiService
        .get('/oferta-log', '?' + this.$stringFormat.queryParams(params))
        .then((res) => {
          this.data = res.data.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.showData = false
        })
    },

    formatDateHour (dateHour) {
      const d = new Date(dateHour)
      return d.toLocaleString('pt-BR')
    },

    formatNumber (number, decimal) {
      return parseFloat(number).toLocaleString('pt-br', { minimumFractionDigits: decimal })
    },

    getMonthDate (numeroMes) {
      const meses = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ]
      return meses[numeroMes - 1]
    },

    async exportFile (tipo) {
      if (this.data.length > 0) {
        const url = tipo === 'Excel' ? '/relatorios/exportar' : '/relatorios/exportar-pdf'
        const filtros = {
          empresa_id: this.empresaId,
          producao_id: this.producaoId,
          talhao_id: this.talhaoId,
          ano: this.ano,
        }

        const params = {
          relatorio: tipo === 'Excel' ? 'AlteracoesOfertaGrama' : 'alteracoes-oferta-grama/alteracoes-oferta-grama',
          parametros: JSON.stringify(filtros),
        }
        this.snackbarDownload = true
        this.snackbarDownloadColor = 'success'
        this.snackDownloadTitle = 'Gerando o arquivo'
        this.snackDownloadText = 'Aguarde alguns segundos para baixar o arquivo'

        this.$refs.snackbar.show('Gerando o arquivo', 'Aguarde alguns segundos para baixar o arquivo', 'success', -1, true)

        ApiService.post(url, params).then((res) => {
          if (res.data.data.arquivo !== '') {
            this.$refs.snackbar.show('Arquivo gerado com sucesso', '', 'success')
            window.open(res.data.data.arquivo, '_blank')
          } else {
            this.$refs.snackbar.show('Erro', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger')
          }
        }).catch((error) => {
          this.$refs.snackbar.show('Erro', 'Não foi possível gerar o arquivo, tente novamente mais tarde.', 'danger')
          console.error('Erro: ', error)
        })
      } else {
        this.$refs.snackbar.show('Erro', 'Não foram encontrados dados para exportação!', 'danger')
      }
    },
  },
}
</script>
